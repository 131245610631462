import React, { useState } from 'react';
import { Box, Heading, VStack, FormControl, FormLabel, Input, Button, Text, Alert, AlertIcon, Spinner } from '@chakra-ui/react';
import { signOut } from 'aws-amplify/auth';
import axios from 'axios';

function AIReadinessAssessment() {
  const [formData, setFormData] = useState({
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: ''
  });
  const [strategy, setStrategy] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setStrategy('');
    try {
      const response = await axios.post('/api/assessment', formData);
      console.log(response.data);
      setStrategy(response.data.strategy);
    } catch (error) {
      console.error('Error submitting assessment:', error);
      setError('Failed to submit assessment. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Box maxWidth="800px" margin="auto" padding="20px">
      <VStack spacing={6} align="stretch">
        <Heading>AI Readiness Assessment</Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Question 1: How would you rate your organization's current AI capabilities?</FormLabel>
              <Input name="q1" value={formData.q1} onChange={handleChange} required />
            </FormControl>
            <FormControl>
              <FormLabel>Question 2: What is your primary goal for implementing AI?</FormLabel>
              <Input name="q2" value={formData.q2} onChange={handleChange} required />
            </FormControl>
            <FormControl>
              <FormLabel>Question 3: How much of your budget is allocated to AI initiatives?</FormLabel>
              <Input name="q3" value={formData.q3} onChange={handleChange} required />
            </FormControl>
            <FormControl>
              <FormLabel>Question 4: What is your biggest challenge in adopting AI?</FormLabel>
              <Input name="q4" value={formData.q4} onChange={handleChange} required />
            </FormControl>
            <FormControl>
              <FormLabel>Question 5: How would you describe your organization's data infrastructure?</FormLabel>
              <Input name="q5" value={formData.q5} onChange={handleChange} required />
            </FormControl>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Submit Assessment
            </Button>
          </VStack>
        </form>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {isLoading && (
          <Box textAlign="center">
            <Spinner size="xl" />
            <Text mt={2}>Generating AI Strategy...</Text>
          </Box>
        )}
        {strategy && (
          <Box borderWidth={1} borderRadius="lg" padding={4}>
            <Heading size="md" mb={2}>Your AI Strategy:</Heading>
            <Text whiteSpace="pre-wrap">{strategy}</Text>
          </Box>
        )}
        <Button onClick={handleSignOut} colorScheme="red">Sign Out</Button>
      </VStack>
    </Box>
  );
}

export default AIReadinessAssessment;
