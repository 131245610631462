import React from 'react';
import { Box, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { signIn } from 'aws-amplify/auth';

function PublicPage() {
  const handleSignIn = () => {
    signIn();
  };

  return (
    <Box>
      <VStack spacing={4}>
        <Heading>Welcome to AI Strategy Suite</Heading>
        <Text>Assess your AI readiness and get personalized strategies.</Text>
        <Button as={Link} to="/assessment" colorScheme="blue">
          Start Assessment
        </Button>
        <Button onClick={handleSignIn}>Sign In</Button>
      </VStack>
    </Box>
  );
}

export default PublicPage;
