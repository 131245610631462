import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ChakraProvider, Box, VStack, Grid, theme } from '@chakra-ui/react';

import PublicPage from './components/PublicPage';
import AIReadinessAssessment from './components/AIReadinessAssessment';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Authenticator>
        {({ signOut, user }) => (
          <Router>
            <Box textAlign="center" fontSize="xl">
              <Grid minH="100vh" p={3}>
                <VStack spacing={8}>
                  <Routes>
                    <Route path="/" element={<PublicPage />} />
                    <Route 
                      path="/assessment" 
                      element={
                        user ? <AIReadinessAssessment /> : <Navigate to="/" replace />
                      } 
                    />
                  </Routes>
                </VStack>
              </Grid>
            </Box>
          </Router>
        )}
      </Authenticator>
    </ChakraProvider>
  );
}

export default App;
